import React, {useEffect, useRef, useState} from "react";
import * as LiveMatchApi from "../../api/LiveMatchApi";
import Hls from 'hls.js';

export default function StreamPlayer({streamMainData, selectedStream}) {
    const videoRef = useRef(null);

    const [streamInfo, setStreamInfo] = useState(null);
    const [streamDetails, setStreamDetails] = useState(null);
    const [streamUrl, setStreamUrl] = useState("");
    const [commentList, setCommentList] = useState([]);
    const [submittedComment, setSubmittedComment] = useState('');


    useEffect(() => {
        if (streamMainData){
            setStreamInfo(streamMainData);
        }
        if (selectedStream) {
            setStreamDetails(selectedStream);
            setStreamViewUrl();
            fetchAllComments(selectedStream.id)
        }
    }, [streamMainData, selectedStream]);

    useEffect(() => {
        const video = videoRef.current;

        if (Hls.isSupported()) {
            const hls = new Hls();
            hls.loadSource(streamUrl);
            hls.attachMedia(video);
            hls.on(Hls.Events.MANIFEST_PARSED, () => {
                video.play();
            });

            return () => {
                hls.destroy();
            };
        } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
            video.src = streamUrl;
            video.addEventListener('loadedmetadata', () => {
                video.play();
            });
        }
    }, [streamUrl]);

    function setStreamViewUrl() {
        const streamKey = selectedStream?.streamUid;

        if (streamKey){
            setStreamUrl(`https://iungolivestream.edooket.com/hls/${streamKey}.m3u8`)
            // setStreamUrl(`http://${ip}:1930/hls/${streamKey}.m3u8`)
            console.log(streamUrl);
        }
    }

    function fetchAllComments(streamId){
        LiveMatchApi.getAllCommentsByStreamId(streamId)
            .then(resp=>{
               setCommentList(resp.data?.data ? resp.data?.data : [])
            })
    }

    const saveComment = () => {
        console.log(submittedComment);
        LiveMatchApi.saveStreamComment({comments: submittedComment, streamId: selectedStream.id})
            .then(resp => {
                console.log(resp);
                if (resp.response.status === 200) {
                    fetchAllComments(selectedStream.id);
                    setSubmittedComment('');
                }
            })
    }

    return (
        <>
            <div className="homepage w-100">
                    <main>
                        {/*<div className="container">*/}
                        {/*    <div className="row">*/}
                        {/*        <h2>{streamInfo?.streamTitle ? streamInfo.streamTitle : 'Test title'}</h2>*/}
                        {/*        <div className="col-sm-12">*/}
                        {/*            /!*this url use for testing purpose*!/*/}
                        {/*            /!*https://bitdash-a.akamaihd.net/content/sintel/hls/playlist.m3u8*!/*/}
                        {/*            <ReactHlsPlayer*/}
                        {/*                src="https://bitdash-a.akamaihd.net/content/sintel/hls/playlist.m3u8"*/}
                        {/*                autoPlay={false}*/}
                        {/*                controls={true}*/}
                        {/*                width="100%"*/}
                        {/*                height="auto"*/}
                        {/*            />*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="video-player-container">
                            <div className="video-overlay">
                                <h2 className="video-title">{streamDetails?.streamTitle ? streamDetails.streamTitle : 'Live Stream'}</h2>
                            </div>
                            {/*<ReactHlsPlayer
                                src={streamUrl}
                                autoPlay={false}
                                controls={true}
                                className="video-player"
                            />*/}

                            <video ref={videoRef} controls style={{ width: '100%', height: 'auto' }}>
                                Your browser does not support HTML5 video.
                            </video>

                            {/* Comment Section */}
                            <div className="comment-section">
                                <h3 className="comment-title">Comments</h3>
                                {/* Input for Adding Comments */}
                                <div className="comment-input">
                                    <img
                                        src="img/Avatar.png"
                                        alt="Your Avatar"
                                        className="comment-avatar-input"
                                    />
                                    <input
                                        type="text"
                                        placeholder="Add a comment..."
                                        onChange={(e) => setSubmittedComment(e.target.value)}
                                        value={submittedComment}
                                        className="comment-input-field"
                                    />
                                    <button className="comment-submit" onClick={saveComment}>Send</button>
                                </div>

                                {/* Comments List */}
                                <div className="comments-list">
                                    {commentList && commentList.length ? (
                                        commentList.map((item, index) => (
                                            <div className="comment" key={index}>
                                                <div className="comment-background">
                                                    <div className="comment-header">
                                                        <img
                                                            src={item.avatar || "img/Avatar.png"}
                                                            alt={`${item.fullName}'s Avatar`}
                                                            className="comment-avatar"
                                                        />
                                                        <div className="comment-info">
                                                            <strong className="comment-name">{item.fullName}</strong>
                                                            <span className="comment-timestamp">
                                                            (5 minutes ago)
                                                        </span>
                                                        </div>
                                                    </div>
                                                    <p className="comment-text">{item.comments}</p>
                                                </div>

                                                <div className="comment-actions">
                                                    <button className="like-button">Like</button>
                                                    <span className="like-count">
                                                        0 Likes
                                                    </span>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <p className="no-comments">No comments yet. Be the first to comment!</p>
                                    )}
                                </div>
                            </div>

                        </div>
                    </main>
            </div>
        </>
    );
}

