import AxiosAgent from "./AxiosAgent";
import {API_MAIN_ROOT} from "../util/LinkFactory";


export const getAllMatchByType = params => {
    return AxiosAgent.requests.getWithHttpParams(API_MAIN_ROOT + "/live-match/all", params);
};

export const getAllStreamByMatchId = id => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/live-match/all-streamer-by-live-match/"+id);
};

export const getAllCommentsByStreamId = id => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/live-match/all-comments-by-stream-id/"+id);
};

export const saveLiveMatchInfo = data => {
    return AxiosAgent.requests.post(API_MAIN_ROOT + "/live-match/save", data);
};

export const saveStreamComment = data => {
    return AxiosAgent.requests.post(API_MAIN_ROOT + "/live-match/save-comment", data);
};
