import React from "react";


function InstructionPage() {


    return (
        <>
            <header>
                <h1>Account Deletion Instructions</h1>
            </header>

            <section>
                <h2>How to Delete Your Account</h2>
                <p>If you wish to permanently delete your account, please follow these steps:</p>

                <ol>
                    <li>Log in to your account using your credentials.</li>
                    <li>Go to your <strong>My Profile</strong> page.</li>
                    <li>In the app bar, you will find the <strong>Delete Account</strong> button.</li>
                    <li>Click on the <strong>Delete Account</strong> button.</li>
                    <li>You will be redirected to the <strong>Delete Account Page</strong>.</li>
                    <li>Provide your <strong>Email</strong> to verify your identity.</li>
                    <li>Click on the <strong>Confirm Deletion</strong> button to permanently delete your account.</li>
                </ol>

                <p>If you have any questions or need assistance, feel free to contact our support team at <a href="mailto:iungotechworld20@gmail.com">iungotechworld20@gmail.com</a>.</p>
            </section>

            <footer>
                <p>&copy; 2025 Iungo World</p>
            </footer>

        </>
    );
}

export default InstructionPage;
