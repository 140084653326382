import React from "react";
// import ReactQueryProvider from "./helpers/ReactQueryProvier";
import ReactDOM from 'react-dom/client';
import "./index.css";
import App from "./App";
import {Provider} from "react-redux";
import {logger} from "redux-logger";
import thunk from "redux-thunk";
import reducer from "./storeManager/reducers";
import {applyMiddleware, compose, createStore} from "redux";
import "babel-polyfill";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "react-datepicker/dist/react-datepicker.css";
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import {BrowserRouter} from "react-router-dom";
import {GoogleOAuthProvider} from "@react-oauth/google";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
    reducer,
    {},
    composeEnhancers(applyMiddleware(thunk, logger))
);
const root = ReactDOM.createRoot(document.getElementById("root"))
const baseHref = document.querySelector('base').getAttribute('href').replace(/\/$/, '');

const render = () =>
    root.render(
        <GoogleOAuthProvider clientId='1081439470101-lh4fbgs4uk9f0mheo432s07ccl9i82lp.apps.googleusercontent.com'>
        <BrowserRouter basename={baseHref}>
            <Provider store={store}>
                <App/>
            </Provider>
        </BrowserRouter>
        </GoogleOAuthProvider>
    );

render();
