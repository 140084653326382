import React, {useEffect, useState} from "react";
import CommonLayout from "../layout/commonLayout";
import Loader from "../common/Loader";
import {useNavigate, useParams} from "react-router-dom";
import * as ChannelApi from "../../api/ChannelApi";
import {toast} from "react-toastify";
import {removeFollow} from "../../api/ChannelApi";

toast.configure();

function FollowerFollowing() {
    let history = useNavigate();
    const params = useParams();
    const SERVER_NAME = process.env.REACT_APP_FILES_URL;

    const [loader, setLoader] = useState(false);
    const [followerList, setFollowerList] = useState([]);
    const [followingList, setFollowingList] = useState([]);

    useEffect(() => {
        getFollowerList();
        getFollowingList()
    }, []);

    const getFollowerList = () => {
        ChannelApi.getFollowerList({query: ""}).then(resp => {
                setFollowerList(resp.data)
            }
        )
    }

    const getFollowingList = () => {
        ChannelApi.getFollowingList({query: ""}).then(resp => {
                setFollowingList(resp.data)
            }
        )
    }

    const handleUnfollow = id => {
        console.log(id);
        ChannelApi.removeFollow(id).then(resp => {
                console.log(resp);
                getFollowingList();
            }
        )
    }


    if (!loader) {
        return (
            <div className=" student-profile ">
                <CommonLayout headerName={"Student Info"}>
                    <main>
                        <div className="pt-3 pb-3 p-2 follower-following d-flex justify-content-center">
                            <div className="card-default">
                                <div className="card-heading">
                                    <div className="title">
                                        <p>Follower/Following</p>
                                    </div>
                                </div>
                                <div className="tab-blue">
                                    <div className="tabs">
                                        <ul className="nav nav-pills mb-4" id="pills-tab3" role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link active" id="pills-assignment-upcoming" data-toggle="pill" href="#pills-assignment" role="tab" aria-controls="pills-assignment" aria-selected="true">
                                                    Followers
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" id="pills-assignment-inprogress" data-toggle="pill" href="#pills-inprogress" role="tab" aria-controls="pills-inprogress" aria-selected="false">
                                                    Following
                                                </a>
                                            </li>
                                        </ul>
                                        <div className="tab-content" id="pills-tabContent">
                                            <div className="tab-pane fade show active" id="pills-assignment" role="tabpanel" aria-labelledby="pills-assignment-upcoming">
                                                <div className="row">
                                                    {followerList && followerList.length ?
                                                        followerList.map((data, index) => {
                                                            return (
                                                                <div className="col-md-6" key={index}>
                                                                    <div
                                                                        className="follower-card d-flex align-items-center p-3 mb-3">
                                                                        <div className="avatar">
                                                                            <img
                                                                                src={data.followedBy.imageUrl ? SERVER_NAME + data.followedBy.imageUrl : "img/Avatar.png"}
                                                                                alt="Avatar"/>
                                                                        </div>
                                                                        <div className="name ms-3">
                                                                            <h5>{data.followedBy.firstName} {data.followedBy.lastName}</h5>
                                                                            <p></p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        }) : null}
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="pills-inprogress" role="tabpanel" aria-labelledby="pills-assignment-inprogress">
                                                <div className="row">
                                                    {followingList && followingList.length ? followingList.map((data, index) => {
                                                        return (
                                                            <div className="col-md-6" key={index}>
                                                                <div
                                                                    className="follower-card d-flex align-items-center p-3 mb-3">
                                                                    <div className="avatar">
                                                                        <img
                                                                            src={data.follower.imageUrl ? SERVER_NAME + data.follower.imageUrl : "img/Avatar.png"}
                                                                            alt="Avatar"/>
                                                                    </div>
                                                                    <div className="name ms-3">
                                                                        <h5>{data.follower.firstName} {data.follower.lastName}</h5>
                                                                        <p></p>
                                                                    </div>
                                                                    <div>
                                                                        <button onClick={()=>handleUnfollow(data.id)}
                                                                            type="button"
                                                                            className="btn-red-lg radius-25">
                                                                            Remove
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    }) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </main>
                </CommonLayout>
            </div>
        );
    }
    return (
        <div className="h-100 my-auto mx-auto pt-4  ">
            <Loader/>
        </div>
    );
}

export default FollowerFollowing;
