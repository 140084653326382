import React, {useEffect, useState} from "react";
import CachingImage from "../../Image/cachingImage";
import ReactPlayer from 'react-player'

const MediaViewComponent = props => {

    const attachment = props.imageurl
    const url = attachment?.imageFileName ? attachment?.imageFileName : attachment?.videoFileName;

    const isImageContent = () => {
        const tempUrl = url ? url.toLowerCase() : "";
        return tempUrl.includes(".jpeg") || tempUrl.includes(".jpg") || tempUrl.includes(".png") || tempUrl.includes(".webp") || tempUrl.includes(".gif");
    }

    return (
        <div className="post-detail-image w-100 h-100 d-flex justify-content-center align-items-center">
            <div className="image-container d-flex justify-content-center align-items-center">
                {isImageContent() ?
                    <CachingImage
                        src={process.env.REACT_APP_FILES_URL + url}
                        alt="Post Image"
                    /> :

                    (url?.includes(".mp4")) ?
                        (
                            <div className="video-container">
                                <ReactPlayer playing={true} controls={true} volume={0}
                                             url={process.env.REACT_APP_FILES_URL + url}/>
                            </div>
                        )
                        :
                        (<a title="Download"
                            className="btn btn-sm btn-success radius-15 mx-2 my-1"
                            target="_blank"
                            href={process.env.REACT_APP_FILES_URL + url}>
                            <i className="fa fa-file-download"/>{" "}
                        </a>)
                }
            </div>
        </div>
    );
};

export default MediaViewComponent;
