import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import * as ChannelApi from "../../api/ChannelApi";
import SkeletonView from "../SkeletonView";
import * as Utils from "../../util/Utils";
import {SERVER_NAME_URL} from "../../constants/Constants";
import {followingFollowerCount, followingFollowerCountByUserId} from "../../api/ChannelApi";

function SideNav(props) {
    const history = useNavigate();
    const [sportsTypeList, setSportsTypeList] = useState([]);
    const [followerFollowingInfo, setFollowerFollowingInfo] = useState(null);
    const userInfo = Utils.getUserFromLocalStorage();

    function navigateTo(pathname) {
        history(pathname);
    }

    useEffect(() => {
        fetchFollowFollowerCount()
        fetchSportsTypeList();
    }, []);

    function fetchSportsTypeList() {
        ChannelApi.getAllSportsTypes()
            .then(resp => {
                if (resp.data) {
                    setSportsTypeList(resp.data)
                }
            })
    }

    function fetchFollowFollowerCount () {
        if (userInfo?.id){
            ChannelApi.followingFollowerCountByUserId(userInfo.id)
                .then(resp => {
                    console.log(resp);
                    if (resp.data) {
                        setFollowerFollowingInfo(resp.data)
                    }
                })
        }
    }

    return (
        <>
            <div id="layoutSidenav_nav">
                <nav className="sb-sidenav border-right-1">
                    <div className="profile-menu d-flex flex-column">
                        <div className="top-profile-section d-flex">
                            <img src={userInfo?.imageUrl ? SERVER_NAME_URL + userInfo?.imageUrl : "img/maleStudent.svg"} alt="profile picture" className="profile-pic"/>
                            <div className="profile-details">
                                <h6>{userInfo && userInfo.lastName ? `${userInfo.firstName} ${userInfo.lastName}` : userInfo.firstName}</h6>
                                <p>{userInfo && userInfo.country ? userInfo.country.country : ""}</p>
                                <p>{(userInfo?.weight ? `${userInfo?.weight}lb | ` : "")+(userInfo?.height ? `${userInfo?.height}ft | ` : "")+(userInfo?.grade ? `${userInfo?.grade} Grade` : "")}</p>
                            </div>
                        </div>
                        <div className="profile-bio">
                            <p>
                                {userInfo?.bio && userInfo?.bio.length > 150 ? userInfo?.bio.substring(0, 150) + "... " : userInfo?.bio}
                                <Link to="/profile">See More</Link>
                            </p>
                        </div>
                        <div className="profile-stats">
                            <div className="follower-following d-flex justify-content-between">
                                <div className=" ">
                                    <p><strong>{followerFollowingInfo?.followerCount ? followerFollowingInfo?.followerCount : "..."}</strong></p>
                                    <p>Followers</p>
                                </div>
                                <div className=" ">
                                    <p><strong>{followerFollowingInfo?.followingCount ? followerFollowingInfo?.followingCount : "..."}</strong></p>
                                    <p>Followings</p>
                                </div>
                            </div>
                            <div className="social-media-links d-flex justify-content-center">
                                {userInfo?.instagramLink ?
                                    (<a rel="noopener noreferrer" className="social-icon" href={userInfo?.instagramLink} target="_blank"><img
                                        src="img/instagram-icon.png" alt="Instagram"/></a>) : null}

                                {userInfo?.tiktokLink ?
                                    (<a rel="noopener noreferrer" className="social-icon" href={userInfo?.tiktokLink} target="_blank"><img
                                        src="img/tiktok-icon.png" alt="TikTok"/></a>) : null}

                                {userInfo?.facebookLink ?
                                    (<a rel="noopener noreferrer" className="social-icon" href={userInfo?.facebookLink} target="_blank"><img
                                        src="img/facebook-icon.png" alt="Facebook"/></a>) : null}

                                {userInfo?.tweeterLink ?
                                    (<a rel="noopener noreferrer" className="social-icon" href={userInfo?.tweeterLink} target="_blank"><img
                                        src="img/twitter-icon.png" alt="Twitter"/></a>) : null}
                            </div>
                        </div>
                    </div>


                    <div className="sb-sidenav-menu">
                        <div className="nav">

                            {!props.loading ? (
                                <div className="w-100">
                                    {sportsTypeList && sportsTypeList
                                            .map((data, index) => (
                                                <a className="nav-link sidenav-border" key={index}>
                                                    <button
                                                        type="button"
                                                        className="dropdown-sidenav py-3"
                                                        onClick={() =>
                                                            navigateTo("/posts/" + data.id)
                                                        }>
                                                        <div className="d-flex justify-content-center">
                                                            <h6 className="  mb-0 mt-1">{data.sportsType}</h6>
                                                        </div>
                                                    </button>
                                                </a>
                                            ))
                                    }
                                </div>
                            ) : (
                                <div className="w-100">
                                    <SkeletonView count={17}/>
                                </div>
                            )}
                        </div>
                    </div>

                </nav>
            </div>
        </>
    );
}

export default SideNav;
