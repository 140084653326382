import React, {useEffect, useState} from "react";

import * as LinkFactory from "../../util/LinkFactory";
import {useNavigate} from "react-router-dom";
import * as CommonApi from "../../api/CommonApi";

function RightBar(props) {
    let history = useNavigate();

    const [opportunityList, setOpportunityList] = useState([]);
    const [promotionList, setPromotionList] = useState([]);

    useEffect(() => {
        fetchOpportunities()
        fetchPromotion()
    }, []);

    function fetchOpportunities() {
        CommonApi.getAllOpportunities()
            .then(resp => {
                setOpportunityList(resp.data ? resp.data : []);
            })
    }

    function fetchPromotion() {
        CommonApi.getAllPromotions()
            .then(resp => {
                setPromotionList(resp.data ? resp.data : []);
            })
    }

    function navigateTo(pathname) {
        history(pathname);
    }

    const handleClickAllOpportunities = () => {
        navigateTo(LinkFactory.opportunities)
    }

    const handleClickAllPromotions = () => {
        navigateTo(LinkFactory.promotions)
    }

    const handleClickEvents = () => {
        navigateTo(LinkFactory.events)
    }

    return (
        <>
            <div className="block-right card-default">
                <div className="card-default-transparent">
                    <div className="rightbar-card">
                        <div className="d-flex justify-content-center mb-1">
                            <h5>Opportunities</h5>
                        </div>

                        {opportunityList && opportunityList.length ?
                            (
                                <>
                                    <div className="details">
                                        <div className="detail">
                                            <div className="mr-3">
                                                <img src="img/company.png" alt="Company Icon" className="icon"/>
                                                <strong>Company:</strong>
                                            </div>
                                            <p>{opportunityList[0].companyName}</p>
                                        </div>
                                        <div className="detail">
                                            <div className="mr-3">
                                                <img src="img/opportunity.png" alt="Opportunity Icon" className="icon"/>
                                                <strong>Opportunity:</strong>
                                            </div>
                                            <p>{opportunityList[0].opportunity}</p>
                                        </div>
                                        <div className="detail">
                                            <div className="mr-3">
                                                <img src="img/industry.png" alt="Industry Icon" className="icon"/>
                                                <strong>Industry:</strong>
                                            </div>
                                            <p>{opportunityList[0].industry?.industry}</p>
                                        </div>
                                        <div className="detail">
                                            <div className="mr-3">
                                                <img src="img/expiration-date.png" alt="Date Icon" className="icon"/>
                                                <strong>Effective From:</strong>
                                            </div>
                                            <p>{opportunityList[0].effectiveDate}</p>
                                        </div>
                                    </div>
                                    <div className="description">
                                        <h4>Description</h4>
                                        <p>{opportunityList[0].opportunityDescription && opportunityList[0].opportunityDescription.length > 80 ?
                                            opportunityList[0].opportunityDescription.substring(0, 80) + "..." : opportunityList[0].opportunityDescription}
                                        </p>
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        <button className="btn-red-md view-more"
                                                onClick={handleClickAllOpportunities}>
                                            {"View " + opportunityList.length + " more"}
                                        </button>
                                    </div>
                                </>)
                            : null}
                    </div>


                    <div className="rightbar-card">
                        <div className="d-flex justify-content-center mb-1">
                            <h5>Promotions</h5>
                        </div>

                        {promotionList && promotionList.length ?
                            (
                                <>
                                    <div className="details">
                                        <div className="detail">
                                            <div className="mr-3">
                                                <img src="img/company.png" alt="Company Icon" className="icon"/>
                                                <strong>Company:</strong>
                                            </div>
                                            <p> {promotionList[0].companyName}</p>
                                        </div>
                                        <div className="detail">
                                            <div className="mr-3">
                                                <img src="img/opportunity.png" alt="Opportunity Icon" className="icon"/>
                                                <strong>Offer:</strong>
                                            </div>
                                            <p>{promotionList[0].offer}</p>
                                        </div>
                                        <div className="detail">
                                            <div className="mr-3">
                                                <img src="img/industry.png" alt="Industry Icon" className="icon"/>
                                                <strong>Industry:</strong>
                                            </div>
                                            <p>{promotionList[0].industry?.industry}</p>
                                        </div>
                                        <div className="detail">
                                            <div className="mr-3">
                                                <img src="img/expiration-date.png" alt="Date Icon" className="icon"/>
                                                <strong>Effective From:</strong>
                                            </div>
                                            <p> {promotionList[0].effectiveDate}</p>
                                        </div>
                                    </div>
                                    <div className="description">
                                        <h4>Description</h4>
                                        <p>
                                            {promotionList[0].offerDescription && promotionList[0].offerDescription.length > 80 ?
                                                promotionList[0].offerDescription.substring(0, 80) + "..." : promotionList[0].offerDescription}
                                        </p>
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        <button className="btn-red-md view-more" onClick={handleClickAllPromotions}>
                                            {"View " + promotionList.length + " more"}
                                        </button>
                                    </div>
                                </>)
                            : null}
                    </div>

                    <div className="rightbar-card">
                        <div className="d-flex justify-content-center mb-1">
                            <h5>Upcoming Events</h5>
                        </div>
                        <div className="details">
                            <div className="detail">
                                <div className="mr-3">
                                    <img src="img/event-name.png" alt="Event Name Icon" className="icon"/>
                                    <strong>Event Name:</strong>
                                </div>
                                <p> Pro holmes sports...</p>
                            </div>
                            <div className="detail">
                                <div className="mr-3">
                                    <img src="img/Event-venue.png" alt="Event Venue Icon" className="icon"/>
                                    <strong>Event Venue:</strong>
                                </div>
                                <p>Graduate Assistant...</p>
                            </div>
                            <div className="detail">
                                <div className="mr-3">
                                    <img src="img/expiration-date.png" alt="Date Icon" className="icon"/>
                                    <strong>Event Date:</strong>
                                </div>
                                <p> 2024-1-20</p>
                            </div>
                        </div>
                        <div className="description">
                            <h4>Description</h4>
                            <p>The central focus for the position is to coordinate and assist with all aspects of the
                                team by performing the...<a href="#">Read more</a></p>
                        </div>
                        <div className="d-flex justify-content-center">
                            <button className="btn-red-md view-more" onClick={handleClickEvents}>View 12 more</button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default RightBar;
