import React, {useEffect, useState} from "react";
import * as EventsApi from "../../api/EventsApi";
import CommonLayout from "../layout/commonLayout";
import {useNavigate} from "react-router-dom";

function EventsList() {

    let history = useNavigate();

    function navigateTo(pathname) {
        history(pathname);
    }

    const [events, setEvents] = useState([]);
    const [initial, setInitial] = useState({name: "", phone: ""});
    const [id, setId] = useState(null);

    useEffect(() => {
    }, []);

    return (
        <>
            <CommonLayout>
                <main>
                    <h1>All events</h1>
                </main>
            </CommonLayout>
        </>
    );
}

export default EventsList;
