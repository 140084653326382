import React, {useEffect, useState} from "react";
import CommonLayout from "../layout/commonLayout";
import * as LiveMatchApi from "../../api/LiveMatchApi";
import moment from "moment";
import {addNewLiveMatch, FILES_URL} from "../../util/LinkFactory";
import * as LinkFactory from "../../util/LinkFactory";
import {useNavigate} from "react-router-dom";


function LiveMatches() {
    const history = useNavigate();

    function navigateTo(pathname) {
        history(pathname);
    }

    const [upcomingMatchList, setUpcomingMatchList] = useState([]);
    const [previousMatchList, setPreviousMatchList] = useState([]);

    /*by azim*/
    const handleHorizontalScroll = (e) => {
        e.preventDefault();
        e.currentTarget.scrollLeft += e.deltaY;
    };


    useEffect(() => {
        getAllLiveMatchListByType('upcoming')
        getAllLiveMatchListByType('previous')
    }, []);

    function getAllLiveMatchListByType(type) {
        LiveMatchApi.getAllMatchByType({type})
            .then(resp => {
                console.log(resp);
                if (type === 'previous') setPreviousMatchList(resp.data?.data ? resp.data.data : [])
                if (type === 'upcoming') setUpcomingMatchList(resp.data?.data ? resp.data.data : [])
            })
    }

    const handleLiveMatchesRoute = (match) => {
        navigateTo(LinkFactory.liveStreams +"/"+ match.id);
    };

    return (
        <>
            <div className="homepage">
                <CommonLayout headerName={""}>
                    <main>
                        <div className="d-flex justify-content-end">
                            <div className="card-default container-responsive live-matches">
                                <div className="left-section-heading d-flex align-items-center">
                                    <button type="button" className="btn-red-lg radius-25" onClick={()=>navigateTo(LinkFactory.addNewLiveMatch)}>
                                        Create Match
                                    </button>
                                    <div className="form-group mb-0 d-flex justify-content-center">
                                        <div className="has-search w-100">
                                            <span className="fa fa-search form-control-feedback"> </span>
                                            <input type="text"
                                                   className="form-control"
                                                   placeholder="Search matches"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 matches-section">
                                        <div className="section-header">
                                            <h2>Upcoming Matches</h2>
                                        </div>
                                        <div className="upcoming-matches-section" onWheel={handleHorizontalScroll}>
                                            {upcomingMatchList && upcomingMatchList.length ? (
                                                upcomingMatchList.map((item, index) => (
                                                    <div className="col-12 match-card" key={index}>
                                                        <div className="card">
                                                            <div className="row card-body">
                                                                <div className="thumbnail-image">
                                                                    <img
                                                                        src={item.thumbnail ? FILES_URL + item.thumbnail : "img/live-match-thumbnail.jpg"}
                                                                        alt={"thumbnail_" + index}
                                                                    />
                                                                </div>
                                                                <div className="match-info">
                                                                    <h5 className="card-title">{item.title}</h5>
                                                                    <p className="card-text">
                                                                        {item.startTime ? moment(item.startTime).format('MMMM Do YYYY, h:mm') : ""}
                                                                    </p>
                                                                    <p className="card-text location">
                                                                        <img src="img/location-icon.png" alt="Location Icon" className="location-icon" />
                                                                        {item.location}
                                                                    </p>
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => handleLiveMatchesRoute(item)}
                                                                        className="btn details-btn"
                                                                    >
                                                                        Details
                                                                    </button>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            ) : null}
                                        </div>
                                    </div>

                                    <div className="col-md-6 matches-section">
                                        <div className="section-header">
                                            <h2>Previous Matches</h2>
                                        </div>
                                        <div className="previous-matches-section" onWheel={handleHorizontalScroll} >
                                            {previousMatchList && previousMatchList.length ? (
                                                previousMatchList.map((item, index) => (
                                                    <div className="match-card" key={index}>
                                                        <div className="card">
                                                            <div className="card-body">
                                                                <div className="match-info">
                                                                    <div>
                                                                        <h5 className="card-title">{item.title}</h5>
                                                                        <p className="card-text">
                                                                            {item.startTime ? moment(item.startTime).format('MMMM Do YYYY, h:mm') : ""}
                                                                        </p>
                                                                        <p className="card-text location">
                                                                            <img src="img/location-icon.png" alt="Location Icon" className="location-icon" />
                                                                            {item.location}
                                                                        </p>
                                                                    </div>
                                                                    <button
                                                                        type="button"
                                                                        className="btn details-btn"
                                                                    >
                                                                        <img src="img/watchnow-icon.png" alt="Location Icon" className="location-icon" />
                                                                        Watch Now
                                                                    </button>
                                                                </div>
                                                                <div className="thumbnail-image">
                                                                    <img
                                                                        src={item.thumbnail ? FILES_URL + item.thumbnail : "img/live-match-thumbnail.jpg"}
                                                                        alt={"thumbnail_" + index}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </main>
                </CommonLayout>
            </div>
        </>
    );
}

export default LiveMatches;
