import React, {useEffect, useRef, useState} from "react";
import * as MessageApi from "../../api/MessageApi";
import * as Utils from "../../util/Utils";
import {useLocation, useNavigate} from "react-router-dom";
import MessageSendingForm from "./messageSendForm";
import "../../style/css/style.scss";
import "./chat.scss";
import MyModal from "../common/MyModal";
import CommonLayoutForChatPage from "../layout/commonLayoutForChatPage";
import * as ChannelApi from "../../api/ChannelApi";
import * as EventsApi from "../../api/EventsApi";
import * as LinkFactory from "../../util/LinkFactory";
import Loader from "../common/Loader";
import {convertUTCDateToLocalDatePostFormat, dateTimeForChat} from "../../util/DateUtils";

function useQuery() {
    const {search} = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function EmptyMessageScreen({loading}) {
    return (
        <div className="my-auto  d-flex align-items-center justify-content-center h-100">
            <div>
                {loading ? <Loader /> : (
                    <div className="d-flex flex-column align-items-center">
                        <img src="img/select-message.png" alt="description" className="mb-1" />
                            <h4>Click on any chat to view it</h4>
                        <h6>To read the message sent by user click on it </h6>
                    </div>
                )}
            </div>
        </div>
    );
}

function MessagesThreads({threadId, receiverId}) {

    let history = useNavigate();

    function navigateTo(pathname) {
        history(pathname);
    }
    const scrollToBottom = () => {
        bottomRef?.current?.scrollIntoView({block: "end", behavior: "smooth"});
    };

    const [loading, setLoading] = useState(false);
    const own = Utils.getUserFromLocalStorage();
    const [messageList, setMessageList] = useState(null);
    const [threadList, setThreadList] = useState(null);
    const [error, setError] = useState(false);
    const IMAGE_BASE_URL = process.env.REACT_APP_FILES_URL;



    const [user, setUser] = useState({});
    const [selectedThread, setSelectedThread] = useState({});


    useEffect(() => {
        setUser(Utils.getUserFromLocalStorage());
        if (!threadList) {
            MessageApi.threadAll()
                .then(json => {
                    if (json.response.status === 200) {
                        setThreadList(json.data);
                    } else setError(true);
                })
                .catch(errors => {
                    console.log(errors);
                })
                .finally(() => setLoading(false));
        }
    }, []);


    useEffect(() => {
        if (threadId) {
            callMessageListApi();
        }
    }, [history, threadId]);

    useEffect(() => {
        if (messageList?.length > 0) {
            scrollToBottom();
        }
    }, [messageList]);

    function callMessageListApi() {
        setLoading(true);
        MessageApi.getMessagesByThreadId(threadId)
            .then(json => {
                if (json.response.status === 200) {
                    setMessageList(json.data);
                    scrollToBottom();
                } else setError(true);
            })
            .catch(errors => {
                setError(true);
            })
            .finally(() => setLoading(false));
    }

    function handleThreadSelect(data) {
        const newLink = "/message-thread/?th=" + data.threadId;
        setSelectedThread(data)
        setLoading(true);
        navigateTo(newLink);
    }

    function messageFirstPage() {
        navigateTo(LinkFactory.messageFirstPage);
    }

    function handleRefetch() {
        callMessageListApi();
    }

    const bottomRef = useRef(null);


    function returnThreadRecipients(thread) {
        return <>
            {thread.recipients.length > 0 &&
                thread.recipients.map((recipient, index) => {
                        if (index < 3)
                            return (recipient.firstName + " " + recipient.lastName + ((index < 2 && thread.recipients.length > 1) ? ", " : ""))
                        if (index === 3) {
                            return " and " + (thread.recipients.length - 3) + " others";
                        } else return;
                    }
                )}
        </>;
    }

    function goBack() {
        navigateTo(-1)
    }
    return (
        <>
            <CommonLayoutForChatPage headerName="Messages">
                <main>
                    <MyModal/>
                    <div id="contentAreaChatPage" className="container-responsive">
                        <div className="block-center">
                            <div className="pt-2 px-2 container-fluid">
                                <div className="row radius-25 overflow-hidden bg-white">
                                    <div className="left-message-body col-3 col-sm-4 px-0">
                                        <div>
                                            <div className=" ">
                                                <div className="left-section-heading d-flex justify-content-between align-items-center">
                                                    <p className=" px-4 h5 mb-0  fw-600">Chats</p>
                                                    <div className="form-group mb-0  w-100 d-flex justify-content-center">
                                                        <div className="has-search w-80">
                                                            <span className="fa fa-search form-control-feedback"> </span>
                                                            <input type="text"
                                                                   className="form-control"
                                                                   placeholder="Search name or email"
                                                            />
                                                        </div>
                                                    </div>
                                                    <button
                                                        type="button"
                                                        className="btn  bg-c-light-orange d-flex align-items-center radius-5"
                                                        onClick={messageFirstPage}
                                                    >+ New</button>
                                                </div>
                                            </div>

                                            <div className="messages-box">
                                                {threadList &&
                                                    threadList.length > 0 &&
                                                    threadList.map(thread => (
                                                        <div key={thread.threadId}>
                                                            <div className="list-group rounded-0">
                                                                <div
                                                                    onClick={() => handleThreadSelect(thread)}
                                                                    className="list-group-item list-group-item-action list-group-item-light rounded-0"
                                                                >
                                                                    <div className=" d-flex justify-content-between align-items-center ">
                                                                        <div className="d-flex">
                                                                            <div className='avatar'>
                                                                                {thread &&
                                                                                thread.recipients.length === 1 &&
                                                                                thread.recipients[0].imageUrl ? (
                                                                                    <img
                                                                                        src={
                                                                                            IMAGE_BASE_URL +
                                                                                            thread.recipients[0].imageUrl
                                                                                        }
                                                                                        alt="user"
                                                                                        width="50"
                                                                                        height="50"
                                                                                        className="rounded-circle"
                                                                                        style={{objectFit: "cover"}}
                                                                                    />
                                                                                ) : <img
                                                                                    src={"img/Avatar.png"}
                                                                                    alt="user"
                                                                                    width="50"
                                                                                    height="50"
                                                                                    className="rounded-circle"
                                                                                    style={{objectFit: "cover"}}
                                                                                />}
                                                                            </div>
                                                                            <div className="media-body ml-3 my-auto align-items-center ">
                                                                                <h6>

                                                                                    {returnThreadRecipients(thread)}
                                                                                </h6>
                                                                                <span> {thread.lastMessage.length > 10 ? thread.lastMessage.substring(0, 20) + " ..." : thread.lastMessage}  </span>
                                                                                {/*<p> {dateTimeForChat(thread.lastMessageTime)}  </p>*/}
                                                                            </div>
                                                                        </div>
                                                                        <div className="message-time">
                                                                            <p>{dateTimeForChat(thread.lastMessageTime)}</p>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="right-message-body col-9 col-sm-8 px-0">
                                        <div className="avatar title-avatar d-flex align-items-center">
                                            <img
                                                src="img/Avatar.png"
                                                alt="user"
                                                width="50"
                                                height="50"
                                                className="rounded-circle"
                                            />
                                            <div className="name">

                                                <p> {selectedThread?.recipients?.length > 0 &&
                                                    selectedThread?.recipients?.map((recipient, index) => (recipient.firstName + " " + recipient.lastName + (index !== (selectedThread?.recipients?.length - 1) ? ", " : "")))}</p>
                                            </div>
                                        </div>

                                        <div className="chat-box px-4 py-5">
                                            {messageList && messageList.length > 0 ? (
                                                messageList.map((messageObject, index) => {
                                                    return (
                                                        <div key={index} ref={bottomRef}>
                                                            {messageObject.sender.id !== own.id ? (
                                                                <div className="media mb-1">
                                                                    <img
                                                                        src={IMAGE_BASE_URL + messageObject.sender?.imageUrl}
                                                                        alt="user"
                                                                        width="45px"
                                                                        height="45px"
                                                                        className="avatar rounded-circle"
                                                                    />
                                                                    <div className="media-body ml-3">

                                                                        <p className="small text-muted mb-1">
                                                                            {selectedThread?.recipients?.length > 1 ? messageObject.sender?.firstName : ""} {messageObject.createDate
                                                                            ? convertUTCDateToLocalDatePostFormat(new Date(messageObject.createDate))
                                                                            : null}
                                                                        </p>
                                                                        <div
                                                                            className="sender-messagebox">
                                                                            <p>
                                                                                {messageObject.message
                                                                                    ? messageObject.message
                                                                                    : ""}
                                                                            </p>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div className="media ml-auto mb-1">
                                                                    <div className="media-body">
                                                                        <p className="small text-muted mb-1">
                                                                            {messageObject.createDate
                                                                                ? convertUTCDateToLocalDatePostFormat(new Date(messageObject.createDate))
                                                                                : null}
                                                                        </p>
                                                                        <div
                                                                            className="receiver-messagebox ">
                                                                            <p className="text-small">
                                                                                {messageObject.message
                                                                                    ? messageObject.message
                                                                                    : null}
                                                                            </p>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    );
                                                })
                                            ) : (
                                                <EmptyMessageScreen loading={loading}/>
                                            )}
                                        </div>

                                        {threadId && (
                                            <MessageSendingForm
                                                sender={own}
                                                receiver={threadList}
                                                threadId={threadId}
                                                refetch={handleRefetch}
                                            />
                                        )}

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </main>
            </CommonLayoutForChatPage>
        </>
    );
}

export default MessagesThreads;
