import AxiosAgent from "./AxiosAgent";
import {API_MAIN_ROOT} from "../util/LinkFactory";


export const getAllOpportunities = params => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/company-partners");
};

export const getAllPromotions = params => {
    return AxiosAgent.requests.get(API_MAIN_ROOT + "/promotions");
};

export const userTypeHeadSearch = params => {
    return AxiosAgent.requests.getWithHttpParams(API_MAIN_ROOT + "/user-search", params);
};
