import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import CommonLayout from "../layout/commonLayout";
import {useNavigate} from "react-router-dom";
import * as CommonApi from "../../api/CommonApi";
import {getAllPromotions} from "../../api/CommonApi";


toast.configure();

function Promotions() {

    const navigate = useNavigate();
    const [promotionList, setPromotionList] = useState([]);

    useEffect(() => {
        fetchData()
    }, []);

    function fetchData() {
        CommonApi.getAllPromotions()
            .then(resp => {
                setPromotionList(resp.data ? resp.data : []);
            })
    }

    return (
        <>
            <div className="homepage">
                <CommonLayout headerName={""}>
                    <main>
                        <div className="d-flex justify-content-end">
                            <div className="card-default content-section container-responsive">
                                <div className="title ">
                                    <div className="mb-0 d-flex justify-content-between">
                                        <div className="card-header p-0 m-0 mr-1 ">
                                            <a className="pl-0 back-button">
                                                <i className="fa fa-chevron-left"> </i> </a>
                                        </div>

                                        <p className="mt-1"> Promotions</p>
                                    </div>
                                </div>

                                <div className="content-box">
                                    {promotionList && promotionList.length ? promotionList.map((item, index) => (
                                        <div className="rightbar-card content-card" key={index}>
                                            <div className="details">
                                                <div className="detail">
                                                    <div className="mr-3">
                                                        <img src="img/company.png" alt="Company Icon" className="icon"/>
                                                        <strong>Company:</strong>
                                                    </div>
                                                    <p>{item.companyName}</p>
                                                </div>

                                                <div className="detail">
                                                    <div className="mr-3">
                                                        <img src="img/opportunity.png" alt="Opportunity Icon"
                                                             className="icon"/>
                                                        <strong>Offer:</strong>
                                                    </div>
                                                    <p>{item.offer}</p>
                                                </div>

                                                <div className="detail">
                                                    <div className="mr-3">
                                                        <img src="img/industry.png" alt="Industry Icon"
                                                             className="icon"/>
                                                        <strong>Industry:</strong>
                                                    </div>
                                                    <p>{item.industry?.industry}</p>
                                                </div>

                                                <div className="detail">
                                                    <div className="mr-3">
                                                        <img src="img/expiration-date.png" alt="Date Icon"
                                                             className="icon"/>
                                                        <strong>Effective From:</strong>
                                                    </div>
                                                    <p>{item.effectiveDate}</p>
                                                </div>
                                            </div>

                                            <div className="description">
                                                <h4>Description</h4>
                                                <p>{item.offerDescription}</p>
                                            </div>

                                            {/*<div className="d-flex justify-content-center">
                                                <button className="btn-red-md view-more">
                                                    View more details
                                                </button>
                                            </div>*/}
                                        </div>
                                    )) : null}
                                </div>

                            </div>
                        </div>
                    </main>
                </CommonLayout>
            </div>
        </>
    );
}

export default Promotions;
