import React, {useEffect} from "react";
import {toast} from "react-toastify";
import CommonLayout from "../layout/commonLayout";


toast.configure();

export default function OpportunitiyDetails() {


    useEffect(() => {
    }, []);


    return (
        <>
            <div className="homepage">
                <CommonLayout headerName={""}>
                    <main>
                        <div className="d-flex justify-content-end">
                            <div className="d-flex justify-content-end">
                                <div className="card opportunity-card p-4">
                                    {/* Header Section */}
                                    <div className="d-flex align-items-center mb-4">
                                        <a className="back-button pr-2">
                                            <i className="fa fa-chevron-left"></i>
                                        </a>
                                        <h2 className="mb-0">Opportunity Details</h2>
                                    </div>

                                    {/* Opportunity Information Section as Table */}
                                    <div className="opportunity-details-table">
                                        <div className="table-row">
                                            <div className="table-cell label-cell">Company</div>
                                            <div className="table-cell value-cell">Pro Holmes Sports</div>
                                        </div>

                                        <div className="table-row">
                                            <div className="table-cell label-cell">Opportunity</div>
                                            <div className="table-cell value-cell">Graduate Assistant</div>
                                        </div>

                                        <div className="table-row">
                                            <div className="table-cell label-cell">Industry</div>
                                            <div className="table-cell value-cell">General Labor</div>
                                        </div>

                                        <div className="table-row">
                                            <div className="table-cell label-cell">Expiration Date</div>
                                            <div className="table-cell value-cell">2024-01-20</div>
                                        </div>
                                    </div>

                                    {/* Description Section */}
                                    <div className="opportunity-description mt-4">
                                        <h4 className="mb-3">Description</h4>
                                        <p>
                                            The central focus for this position is to coordinate and assist with all aspects of the
                                            team by performing essential support activities. This role will require close interaction
                                            with team members to ensure smooth operations.
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </main>
                </CommonLayout>
            </div>
        </>
    );
}