import React, {useEffect, useState} from "react";
import CommonLayout from "../layout/commonLayout";
import * as LiveMatchApi from "../../api/LiveMatchApi";
import * as LinkFactory from "../../util/LinkFactory";
import {FILES_URL, streamsPlayer} from "../../util/LinkFactory";
import {useNavigate, useParams} from "react-router-dom";
import moment from "moment/moment";
import StreamPlayer from "./StreamPlayer";

export default function LiveStreams(props) {
    const navigate = useNavigate();

    const params = useParams();
    const [streamList, seStreamList] = useState([]);
    const [streamInfo, setStreamInfo] = useState(null);
    const [selectedStream, setSelectedStream] = useState(null);

    useEffect(() => {
        console.log(params);
        if (params.id) getAllLiveStreamByLiveMatchId(params.id)
    }, [params.id]);

    function getAllLiveStreamByLiveMatchId(id) {
        LiveMatchApi.getAllStreamByMatchId(id)
            .then(resp => {
                if (resp.response.status===200){
                    setStreamInfo(resp.data.data)
                    seStreamList(resp.data.data.streamerList)
                }
            })
    }

    const handleStreamPlay = (stream) => {
        // navigate(LinkFactory.streamsPlayer + "/" + stream.id, {state: stream});
        setSelectedStream(stream)
    };


    return (
        <>
            <div className="homepage">
                <CommonLayout headerName={""}>
                    <main>
                        <div className="d-flex justify-content-end pl-4">
                            <div className="card-default container-responsive livestream-container">
                                <div className="livestream-row row">

                                    {/* Sidebar Section with Thumbnails */}
                                    <div className="livestream-sidebar col-md-3">
                                        <h4 className="livestream-sidebar-title">Available Camera Feeds</h4>
                                        <div className="match-detail">
                                            <h5 className="card-title">DT vs MDT</h5>
                                            <p className="card-text">
                                                September 29th 2024, 2:47
                                            </p>
                                            <p className="card-text location">
                                                <img src="img/location-icon.png" alt="Location Icon" className="location-icon" />
                                                USA Stadium
                                            </p>
                                        </div>
                                        <div className="livestream-list">
                                            {streamList && streamList.length ? (
                                                streamList.map((item, index) => (
                                                    <div
                                                        key={index}
                                                        className="livestream-thumbnail-box"
                                                        onClick={() => handleStreamPlay(item)}
                                                    >
                                                        <div className="livestream-thumbnail">
                                                            <img
                                                                src={FILES_URL + item.thumbnail}
                                                                alt={"thumbnail_" + index}
                                                                className="livestream-thumbnail-img"
                                                            />
                                                        </div>
                                                        <div className="livestream-info">
                                                            <h5 className="livestream-title">{item.streamTitle}</h5>
                                                            <p className="livestream-location">{item.location}</p>
                                                        </div>
                                                    </div>
                                                ))
                                            ) : (
                                                <p>No streams available.</p>
                                            )}
                                        </div>
                                    </div>

                                    {/* Main Video Player Section */}
                                    <div className="livestream-main-player col-md-9">
                                        <h2 className="livestream-main-title">Live Stream</h2>
                                        {selectedStream ? (
                                            <StreamPlayer streamMainData={streamInfo} selectedStream={selectedStream}/>
                                            /*<div className="livestream-video-container">

                                            </div>*/
                                        ) : (
                                            <p>Select a stream from the list to play.</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </CommonLayout>
            </div>
        </>
    );
}

