import CachingImage from "../../Image/cachingImage";
import * as LinkFactory from "../../../util/LinkFactory";
import {FILES_URL} from "../../../util/LinkFactory";
import moment from "moment";
import {Form, Formik} from "formik";
import React, {useEffect, useState} from "react";
import {convertUTCDateToLocalDatePostFormat} from "../../../util/DateUtils";
import Swal from "sweetalert2";
import PostAttachment from "./PostAttachment";
import * as Utils from "../../../util/Utils";
import * as ChannelApi from "../../../api/ChannelApi";
import {useNavigate} from "react-router-dom";


const PostContainer = ({postList, viewLikedUserModal, initialComment}) => {

    const navigate = useNavigate()

    const [commentId, setCommentId] = useState(null);
    const [selectedPost, setSelectedPost] = useState(null);
    const [localPostList, setLocalPostList] = useState(postList);
    const [selectedPostId, setSelectedPostId] = useState(null);

    const userObj = Utils.getUserFromLocalStorage();


    useEffect(() => {
        setLocalPostList([...postList]);
    }, [postList]);

    const updatePostList = () => {
        let tempPost = localPostList;
        const postIndex = tempPost.findIndex(i => i.groupMessageId === selectedPost?.groupMessageId)
        if (postIndex > -1) {
            selectedPost.channelPostCommentDTOList = selectedPost?.channelPostCommentDTOList.filter(item => item.id !== commentId);
            tempPost[postIndex] = selectedPost;
            setLocalPostList([...tempPost]);
            showAlert('Comment Deleted');
        }
    }

    const showAlert = (title) => {
        Swal.fire({
            position: 'center',
            icon: 'success',
            title: title,
            showConfirmButton: true,
            confirmButtonText: 'Ok'
        })
    }


    function updatePostListAfterHideOrDelete() {
        let tempPostList = localPostList;
        console.log(tempPostList);
        const postIndex = tempPostList.findIndex(i => i.groupMessageId === selectedPostId)
        if (postIndex > -1) {
            tempPostList.splice(postIndex, 1);
            setLocalPostList([...tempPostList])
        }
        setSelectedPostId(null);
    }

    const onSubmitComment = (data, resetForm, post) => {
        if (!Utils.checkNOTNullEmptyStringUndefinedAndEmptyObject(data.comment)) {
            Swal.fire("Write something to comment", "", "info");
            return;
        }
        updateCommentList(post, data);
        const params = {comment: data.comment, id: data.messageId};
        ChannelApi.savePostComment(params).then(response => {
            console.log(response);
            if (!response.response || response.response.status !== 201) {
                updateCommentList(post, data, true);
            }
        });
        resetForm();
    }

    function updateCommentList(post, data, isFailed = false) {

        const postTemp = [...localPostList];
        const postIndex = localPostList.indexOf(post);

        if (isFailed) {
            const commentIndex = postTemp[postIndex].channelPostCommentDTOList.length - 1 < 0 ? 0 : postTemp[postIndex].channelPostCommentDTOList.length - 1
            postTemp[postIndex].channelPostCommentDTOList.splice(commentIndex, 1);
        } else {
            let commentObj = {
                comment: data.comment,
                firstName: userObj.firstName,
                lastName: userObj.lastName,
                imageUrl: userObj.imageUrl,
                createTimeDate: new Date()
            };

            postTemp[postIndex].channelPostCommentDTOList.push(commentObj)
        }

        setLocalPostList(postTemp);

    }

    const registerLikeUnlike = (post) => {
        updateLikeCount(post);
        const params = {id: post.post.id};
        ChannelApi.registerLike(params).then(resp => {
            console.log(resp);
            if (!resp.response || resp.response.status !== 200) {
                updateLikeCount(post);
            }
        })
    }

    function updateLikeCount(post) {
        const newPosts = [...localPostList];
        const postIndex = localPostList.indexOf(post);
        newPosts[postIndex].likeByUser = !newPosts[postIndex].likeByUser;
        newPosts[postIndex].post.likeCount = newPosts[postIndex].likeByUser ? (+newPosts[postIndex].post.likeCount) + 1 : (+newPosts[postIndex].post.likeCount) - 1;
        setLocalPostList(newPosts);
    }

    const navigateToPostDetails = postId => {
        navigate(LinkFactory.postDetails + "/" + postId);
    }

    const renderLikeCommentCount = (post, label) => {
        let countText = '';
        if (post && post.post) {
            if (label === 'Like' && +post.post.likeCount) {
                countText = post.post.likeCount;
                label = +post.post.likeCount === 1 ? label : ' Likes'
            } else if (label === 'Comment' && post.channelPostCommentDTOList.length) {
                countText = post.channelPostCommentDTOList.length;
                label = post.channelPostCommentDTOList.length === 1 ? label : ' Comments'
            }
        }
        return (
            <>
                <b>{countText}</b> {label}
            </>
        )
    }


    return (
        <>

            {localPostList.length > 0 ? localPostList.map((post, index) => {
                    return (
                        <div className="card-default table-shadow box-border-sm" key={index}>
                            <div className="view-post-area">

                                <div className="">
                                    <div className="post-content">
                                        <div className="post-by d-flex justify-content-between ">
                                            <div className="d-flex">
                                                <div className="avatar">
                                                    {post?.post?.user?.imageUrl ?
                                                        (
                                                            <CachingImage alt="Image"
                                                                          src={FILES_URL + post?.post?.user?.imageUrl}/>
                                                        ) :
                                                        (
                                                            <img src={"img/Avatar.png"} alt="Default Avatar"/>
                                                        )}
                                                </div>
                                                <div className="post-by-info">
                                                    <h6>{post?.post?.user?.firstName} {post?.post?.user?.lastName}</h6>
                                                    <p onClick={()=>navigateToPostDetails(post?.post.id)}>{convertUTCDateToLocalDatePostFormat(post?.post?.timestamp ? new Date(post?.post?.timestamp) : null)}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <p>{post.post.postText}</p>
                                        <PostAttachment postInfo={post}/>
                                        <div className="post-status" onClick={() => {
                                            viewLikedUserModal(post);
                                        }}>
                                        </div>

                                        <div className="like-comment-share">
                                            <div className="box" onClick={() => {
                                                registerLikeUnlike(post);
                                            }}>
                                                <i className={post?.likeByUser ? "fa fa-thumbs-up" : "far fa-thumbs-up"}/>
                                                <p>{renderLikeCommentCount(post, 'Like')}</p>
                                            </div>

                                            <div className="box">
                                                <i className="far fa-comment-dots" />
                                                <p>{renderLikeCommentCount(post, 'Comment')}</p>
                                            </div>

                                            <div className="box">
                                                <p>
                                                    <img src="img/share-icon.png" className="share-icon" alt="Share Icon" />
                                                    Share
                                                </p>
                                            </div>
                                        </div>

                                        <div className="mb-2 mt-2">
                                            {post?.channelPostCommentDTOList.length > 0
                                                ? post?.channelPostCommentDTOList.map(
                                                    (comment, index) => {
                                                        return (
                                                            <div className="comment-area" key={index}>
                                                                <div className="avatar">
                                                                    {comment.imageUrl ?
                                                                        (<img src={FILES_URL + comment.imageUrl}
                                                                              alt="User Avatar"/>)
                                                                        :
                                                                        (<img src={"img/Avatar.png"} alt="Default Avatar"/>)
                                                                    }
                                                                </div>
                                                                <div className="username-time">
                                                                    <div>
                                                                        <div className="card-alt">
                                                                            <h6>{comment.firstName}{" "}{comment.lastName}</h6>
                                                                            <p>{comment.comment}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <span>{" "}<small>{moment(comment.createTimeDate).fromNow()}</small></span>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    }
                                                )
                                                : ""}
                                            {post?.hasMoreComment && <a href="#" onClick={e => {
                                                navigateToPostDetails(post?.post.id);
                                                e.preventDefault()
                                            }}>See more comments...</a>}
                                        </div>

                                        <Formik
                                            initialValues={initialComment}
                                            enableReinitialize={true}
                                            onSubmit={(data, {resetForm}) => {
                                                const value = {...data, messageId: post?.post.id};
                                                onSubmitComment(value, resetForm, post);
                                            }}>

                                            {({values, handleSubmit, setFieldValue}) => (
                                                <Form>
                                                    <div className="add-comment-area">
                                                        <div className="avatar">
                                                            {userObj?.imageUrl ? (
                                                                <CachingImage alt="image"
                                                                              src={FILES_URL + userObj.imageUrl}/>
                                                            ) : (
                                                                <img src={"img/Avatar.png"} alt="Default Avatar"/>
                                                            )}
                                                        </div>
                                                        <div className="input-default">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="comment"
                                                                aria-describedby="emailHelp"
                                                                name="comment"
                                                                placeholder="Comment here..."
                                                                onChange={event => {
                                                                    setFieldValue("comment", event.target.value);
                                                                }}
                                                            />
                                                        </div>
                                                        <button type="submit"
                                                                className="btn-red-md float-right py-2 ml-1">Submit
                                                        </button>
                                                    </div>
                                                </Form>
                                            )}
                                        </Formik>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })
                : ""}
        </>
    );
};

export default PostContainer;
