import React, {useEffect, useState} from "react";
import SingleAttachmentComponent from "../Components/SingleAttachmentComponent";
import TwoAttachmentsComponent from "../Components/TwoAttachmentsComponent";
import ThreeAttachmentsComponent from "../Components/ThreeAttachmentsComponent";
import FourAttachmentComponent from "../Components/FourAttachmentComponent";
import MoreThanFourAttachmentComponent from "../Components/MoreThanFourAttachmentComponent";

export function PostAttachment(props) {

    const postDetails = props.postInfo

    return (
        <>
            {postDetails?.postContentDTO?.length === 1 ? (
                <SingleAttachmentComponent
                    id={postDetails?.post?.id}
                    imageurl={postDetails?.postContentDTO[0]}
                />
            ) : null}

            {postDetails?.postContentDTO?.length === 2 ? (
                <TwoAttachmentsComponent
                    id={postDetails?.post.id}
                    imageurl={postDetails?.postContentDTO}
                />
            ) : null}

            {postDetails?.postContentDTO?.length === 3 ? (
                <ThreeAttachmentsComponent
                    id={postDetails?.post.id}
                    imageurl={postDetails?.postContentDTO}
                />
            ) : null}
            {postDetails?.postContentDTO?.length === 4 ? (
                <FourAttachmentComponent
                    id={postDetails?.post.id}
                    imageurl={postDetails?.postContentDTO}
                />
            ) : null}
            {postDetails?.postContentDTO?.length > 4 ? (
                <MoreThanFourAttachmentComponent
                    id={postDetails?.post.id}
                    imageurl={postDetails?.postContentDTO}
                />
            ) : null}
        </>
    );
}

export default PostAttachment;
