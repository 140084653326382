import React, {useEffect, useState} from "react";
import {Field, Form, Formik} from "formik";
import SelectDropdown from "../common/SelectDropwodn";
import * as Utils from "../../util/Utils";
import * as ChannelApi from "../../api/ChannelApi";
import * as MessageApi from "../../api/MessageApi";
import "./message.css";
import Swal from "sweetalert2";
import {useNavigate} from "react-router-dom";
import MyModal from "../common/MyModal";
import CommonLayoutForChatPage from "../layout/commonLayoutForChatPage";
import Loader from "../common/Loader";
import * as LinkFactory from "../../util/LinkFactory";
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import * as CommonApi from "../../api/CommonApi";

const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
        confirmButton: "btn btn-success mx-3", cancelButton: "btn btn-secondary mr-1"
    }, buttonsStyling: false
});


function DirectMessageFirstPage(props) {
    let history = useNavigate();

    function onSelect(selectedList, selectedItem) {
        if (selectedList && selectedList.length > 0) {
            setSelectedRecipients(selectedList);
        }else {
            setSelectedRecipients([]);
        }
    }

    function onRemove(selectedList, removedItem) {
        let selectedUsers = selectedList;
        setSelectedRecipients(selectedUsers);
    }

    function navigateTo(pathname) {
        history(pathname);
    }

    const [messageInit, setMessageInit] = useState({
        states: "", id: "", message: ""
    });
    const [loading, setLoading] = useState(false);
    const [loader, setLoader] = useState(false);
    const [threadList, setThreadList] = useState(null);
    const [recipientList, setRecipientList] = useState([]);
    const [receiver, setReceiver] = useState(undefined);
    const [selectedUserType, setSelectedUserType] = useState([]);
    const [selectedRole, setSelectedRole] = useState([]);
    const [selectedRecipients, setSelectedRecipients] = useState([]);
    const [channelList, setChannelList] = useState([]);
    const [roles, setRoles] = useState([{role: "Student"}, {role: "Employee"}, {role: "Parents"}]);
    const [message, setMessage] = useState("");
    const [error, setError] = useState(false);
    const IMAGE_BASE_URL = process.env.REACT_APP_FILES_URL;
    const [userTypes, setUserTypes] = useState([]);
    const [selectedValue, setSelectedValue] = useState([]);
    const [userSearchList, setUserSearchList] = useState([]);
    const [isTypeheadLoading, setIsTypeheadLoading] = useState(false);


    useEffect(() => {
        if (!threadList) {
            setLoading(true);

            MessageApi.threadAll().then(json => {
                if (json.response.status === 200) {
                    setThreadList(json.data);
                } else setError(true);
            })
                .catch(errors => {
                    console.log(errors);
                })
                .finally(() => setLoading(false));
        }

        ChannelApi.getUserTypes().then(json => {
            setUserTypes(json.data);
        })
    }, []);

    function returnThreadRecipients(thread) {
        return <>
            {thread.recipients.length > 0 &&
                thread.recipients.map((recipient, index) => {
                        if (index < 3){
                            return (recipient.firstName + " " + recipient.lastName + ((index < 2 && thread.recipients.length > 1) ? ", " : ""))
                        }
                        if (index === 3) {
                            return " and " + (thread.recipients.length - 3) + " others";
                        }
                    }
                )}
        </>;
    }

    const handleSubmit = data => {
        setLoader(true);
        if (message.trim().length === 0) {
            swalWithBootstrapButtons
                .fire({
                    title: "Oops...",
                    text: "You forget to type message..",
                    icon: "error",
                    confirmButtonText: "Okay",
                    reverseButtons: true
                }).then(result => {
                setLoader(false)
            });

            return
        }

        let directGroupMessageThreadDTO = {
            sender: Utils.getUserFromLocalStorage(), message: message, receiverList: selectedRecipients
        };

        MessageApi.sendGroupMessage(directGroupMessageThreadDTO).then(data => {
            setMessage("");

            let slug = "?th=" + data.data.directMessageThread.id;
            setLoader(false);
            swalWithBootstrapButtons
                .fire({
                    title: "Success",
                    text: "Message sent",
                    icon: "success",
                    showCancelButton: true,
                    confirmButtonText: "Go to messages",
                    cancelButtonText: "Okay",
                    reverseButtons: true
                })
                .then(result => {
                    if (result.isConfirmed) {
                        navigateTo("/message-thread/" + slug);
                    }
                });
        });

    };

    const handleUserTypeSelection = (fieldName, value) => {
        /*console.log(value);
        setSelectedUserType(value);
        handleRecipientListLoading(value, "");*/
    };
    const handleRoleSelection = (fieldName, value) => {
        setSelectedRole(value);
        console.log(value);
        console.log(selectedUserType);
        handleRecipientListLoading(selectedUserType, value);
    };

    function displayNameConverter(data) {
        data.forEach(d => {
            d.displayValue = d.firstName + " " + d.lastName
        });
        return data;
    }

    const handleRecipientListLoading = (userType, role) => {
        ChannelApi.getRecipientByUserType({userTypeId: userType.id}).then(json => {
            setRecipientList(displayNameConverter(json.data));
        });
    };

    function handleThreadSelect(data) {
        const newLink = "/message-thread/?th=" + data.threadId;
        navigateTo(newLink);
    }

    function goBack() {
        navigateTo(-1)
    }

    const handleCancel = () => {
        navigateTo(LinkFactory.messageThread);
    };

    function fetchUser(q) {
        setIsTypeheadLoading(true)
        CommonApi.userTypeHeadSearch({userTypeId: selectedUserType ? selectedUserType.id : "", q})
            .then(resp => {
                setIsTypeheadLoading(false)
                setUserSearchList(resp.data ? resp.data : [])
            })
    }

    return (<>
        <CommonLayoutForChatPage headerName="Messages">
            <main>
                <MyModal/>
                <div id="contentAreaChatPage" className="container-responsive">
                    <div className="block-center">
                        <div className="pt-2 px-2 container-fluid">
                            <div className="row radius-25 overflow-hidden bg-white">
                                <div className="left-message-body col-3 col-sm-4 px-0">
                                    <div>
                                        <div className=" ">
                                            <div className="title-header">
                                                <div className=" card-header p-0 m-0 mr-3 d-flex align-items-center">
                                                    <a onClick={goBack} className="pl-0 back-button">
                                                        <i className="fa fa-chevron-left pr-1"> </i> </a>
                                                </div>
                                            </div>
                                            <div className="left-section-heading py-2 px-2 pr-4">
                                                <p className=" px-4 h5 mb-0 py-3 fw-600">Recent Chats</p>
                                            </div>
                                            <div className="form-group mt-1 mb-3 w-100 d-flex justify-content-center">
                                                <div className="has-search w-80">
                                                    <span className="fa fa-search form-control-feedback"> </span>
                                                    <input type="text"
                                                           className="form-control"
                                                           placeholder="Search Chat"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="messages-box">
                                            {threadList && threadList.length && threadList.map(thread => (
                                                <div key={thread.threadId}>
                                                    <div className="list-group rounded-0">
                                                        <div onClick={() => handleThreadSelect(thread)}
                                                             className="list-group-item list-group-item-action list-group-item-light rounded-0">
                                                            <div
                                                                className=" d-flex justify-content-between align-items-center ">
                                                                <div className="d-flex">
                                                                    <div className='avatar'>
                                                                        {thread && thread.recipients[0] && thread.recipients[0].imageUrl ? (
                                                                            <img
                                                                                src={IMAGE_BASE_URL + thread.recipients[0].imageUrl}
                                                                                alt="user"
                                                                                width="50"
                                                                                height="50"
                                                                                className="rounded-circle"
                                                                                style={{objectFit: "cover"}}
                                                                            />) : <img
                                                                            src={"img/Avatar.png"}
                                                                            alt="user"
                                                                            width="50"
                                                                            height="50"
                                                                            className="rounded-circle"
                                                                            style={{objectFit: "cover"}}
                                                                        />}
                                                                    </div>
                                                                    <div
                                                                        className="media-body ml-3 my-auto align-items-center ">
                                                                        <h6>{returnThreadRecipients(thread)}</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="unread-time">
                                                                    <span> 4m</span>
                                                                    <svg width="10" height="10" fill="none"
                                                                         xmlns="http://www.w3.org/2000/svg">
                                                                        <circle cx="5" cy="5" r="5" fill="#4575EE"/>
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>))}
                                        </div>
                                    </div>
                                </div>

                                <div className="right-message-body create-new-chat col-9 col-sm-8 px-0">
                                    <div className="avatar title-avatar d-flex align-items-center">

                                        <div className="name">
                                            <p> Create new conversation</p>
                                        </div>
                                    </div>
                                    <div className="px-4 py-5 chat-box bg-white">
                                        <Formik initialValues={messageInit}
                                                enableReinitialize={true}
                                                onSubmit={handleSubmit}>
                                            {props => (<Form>
                                                <div className="row col-12">
                                                    <div className="col-5">
                                                        <div className="form-group">
                                                            <label>User Type</label>
                                                            <Field type="text"
                                                                   name="channelName"
                                                                   placeholder="Select Type"
                                                                   fieldName={"userType"}
                                                                   options={userTypes || []}
                                                                   handleChangeSection={handleUserTypeSelection}
                                                                   component={SelectDropdown}
                                                            />
                                                        </div>
                                                    </div>
                                                    {/*<div className="col-4">
                                                        <div className="form-group">
                                                            <label>Role</label>
                                                            <Field
                                                                type="text"
                                                                name="Role"
                                                                placeholder="Select Role"
                                                                fieldName={"role"}
                                                                handleChangeSection={handleRoleSelection}
                                                                options={roles || []}
                                                                component={SelectDropdown}
                                                            />
                                                        </div>
                                                    </div>*/}
                                                </div>

                                                {/*<div className="col-12 mt-2 pr-5">
                                                    {recipientList.length > 0 ? (<Multiselect
                                                            onRemove={onRemove}
                                                            onSelect={onSelect}
                                                            options={recipientList}
                                                            selectedValues={selectedValue}
                                                            displayValue="displayValue"
                                                        />

                                                    ) : null}
                                                </div>*/}

                                                <div className="col-12 mt-2 pr-5">
                                                    <AsyncTypeahead
                                                        multiple
                                                        onChange={(selected) => onSelect(selected,null)}
                                                        // onInputChange={(query) => fetchUser(query)}
                                                        minLength={3}
                                                        onSearch={fetchUser}
                                                        isLoading={isTypeheadLoading}
                                                        labelKey="fullName"
                                                        options={userSearchList}
                                                        placeholder="Search for a user..."
                                                    />
                                                </div>

                                                <div className="row col-12 mt-2">
                                                    {selectedRecipients.length > 0 ? (<div className="col-12">
                                                            <div className="form-group">
                                                                <label
                                                                    htmlFor="exampleFormControlTextarea1">
                                                                    Message
                                                                </label>
                                                                <textarea
                                                                    placeholder="Type your message"
                                                                    name="message"
                                                                    className="form-control"
                                                                    onChange={e => setMessage(e.target.value)}
                                                                    rows="3"
                                                                />
                                                            </div>
                                                        </div>

                                                    ) : ("")}
                                                </div>
                                                <div className="row col-12 mt-3">
                                                    <div className="form-group w-100">
                                                        {loader ? <Loader/> :
                                                            <div className="col-12 text-right">
                                                                <button onClick={handleCancel}
                                                                    type="cancel"
                                                                    className="btn btn-cancel radius-25 px-4 mr-3">
                                                                    Cancel
                                                                </button>
                                                                <button
                                                                    disabled={receiver === undefined && message.length === 0}
                                                                    type="submit"
                                                                    className="btn btn-red-md radius-25 px-4 ">
                                                                    Create
                                                                </button>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>

                                            </Form>)}
                                        </Formik>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </CommonLayoutForChatPage>
    </>);
}

export default DirectMessageFirstPage;
