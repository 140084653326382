import React from "react";
import CachingImage from "../../Image/cachingImage";
import {useNavigate} from "react-router-dom";
import * as LinkFactory from "../../../util/LinkFactory";

const TwoAttachmentsComponent = props => {
    const attachments = props.imageurl
    const urls = attachments.map(function(attachment) {
        return attachment.imageFileName ? attachment.imageFileName : attachment.videoFileName;
    });

    const postId = props.id;
    let history = useNavigate();

    const isImageContent = (fileName) => {
        console.log(fileName);
        const tempFileName = fileName ? fileName.toLowerCase() : "";
        return tempFileName.includes(".jpeg") || tempFileName.includes(".jpg") || tempFileName.includes(".png") || tempFileName.includes(".webp") || tempFileName.includes(".gif");
    }
    function navigateTo() {
        if (postId) {
            history(LinkFactory.postDetails + "/" + postId);
        }
    }

    return (
        <div className="w-100" onClick={() => navigateTo()}>
            <div className="grid-fixed-2">
                {urls.length > 0
                    ? urls.map((attachment, index) => {
                        return (
                            <>
                                {isImageContent(attachment) ?
                                    <CachingImage
                                        src={process.env.REACT_APP_FILES_URL + attachment}
                                        alt="Post Image"
                                    /> :
                                    <a title="Download"
                                       className="btn btn-sm btn-success radius-15 mx-2 my-1"
                                       target="_blank"
                                       href={process.env.REACT_APP_FILES_URL + attachment}>
                                        <i className="fa fa-file-download"/>{" "}
                                    </a>}
                            </>
                        );
                    })
                    : null}
            </div>
        </div>
    );
};

export default TwoAttachmentsComponent;
