import fetch from "cross-fetch";
import Cookies from "js-cookie";
import * as AuthApi from "./AuthApi";
import *  as Utils from "../util/Utils";
import {REFRESH_TOKEN} from "../constants/Constants";

const API_ROOT = process.env.REACT_APP_JAVA_APP_URL;
const API_MAIN_ROOT = process.env.REACT_APP_JAVA_MAIN_APP_URL;

const Auth = {
    login: form => {
        return requests.login(API_MAIN_ROOT + "/authenticate", {
            email: form.lgEmail,
            password: form.passwd
        });
    },
    createRefreshToken: () => {
        requests.createRefreshToken();
    },
    forgotPassword: form => {
        return requests.forgotPassword(
            API_MAIN_ROOT + "/users/account/send-otp-by-email",
            {'email': form.email}
        );
    },
    resetPassword: data => {
        return requests.resetPassword(
            API_MAIN_ROOT + "/users/account/reset-password-otp", data);
    },
    quickSignUp: data => {
        return requests.socialMediaSignup(
            API_MAIN_ROOT + "/auth/social-media/signup", data);
    }
};

const Register = {

    verifyOtp: otp => {
        return requests.getForUnAuthenticated(
            "/user/confirm/" + otp
        );
    },

    removeFile: dto => {
        return requests.deleteForUnAuthenticated(
            API_MAIN_ROOT + "/attachment-managements/" + dto.id,
            {}
        );
    },
};

const requests = {
    login: (url, param) => {
        let form = new FormData();
        form.append("username", param.email);
        form.append("password", param.password);
        return fetch(url, {
            method: "post",
            body: form,
            headers: {
                "Access-Control-Allow-Credentials": true
            }
        })
            .then(
                response => {
                    return requestUtil.handleRequest(response);
                },
                error => requestError.errorHandling(error)
            )
            .catch(err => console.error("Caught error: ", err));
    },
    createRefreshToken: () => {
        AuthApi.createRefreshToken({"username": Utils.getUserFromLocalStorage().login})
            .then(json => {
                localStorage.setItem(REFRESH_TOKEN, JSON.stringify(json.data.token));
            })
            .catch(errors => {
                console.log(errors);
            })
    },
    forgotPassword: (url, param) => {
        return fetch(url, {
            method: "post",
            body: JSON.stringify(param),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(
                response => {
                    return requestUtil.handleRequest(response);
                },
                error => requestError.errorHandling(error)
            )
            .catch(err => console.error("Caught error: ", err));
    },
    socialMediaSignup: (url, body) => {
        return fetch(url, {
            method: "post",
            body: JSON.stringify(body),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(
                response => {
                    return requestUtil.handleRequest(response);
                },
                error => requestError.errorHandling(error)
            )
            .catch(err => console.error("Caught error: ", err));
    },
    resetPassword: (url, param) => {
        return fetch(url, {
            method: "post",
            body: JSON.stringify(param),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(
                response => {
                    return requestUtil.handleRequest(response);
                },
                error => requestError.errorHandling(error)
            )
            .catch(err => console.error("Caught error: ", err));
    },
    postForUnAuthenticated: (url, param) => {
        return fetch(API_ROOT + url, {
            method: "post",
            body: JSON.stringify(param),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(
                response => {
                    return requestUtil.handleRequest(response);
                },
                error => requestError.errorHandling(error)
            )
            .catch(err => console.error("Caught error: ", err));
    },
    deleteForUnAuthenticated: (url, param) => {
        return fetch(url, {
            method: "delete",
            body: JSON.stringify(param),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(
                response => {
                    return requestUtil.handleRequest(response);
                },
                error => requestError.errorHandling(error)
            )
            .catch(err => console.error("Caught error: ", err));
    },
    putForUnAuthenticated: (url, param) => {
        return fetch(API_ROOT + url, {
            method: "put",
            body: JSON.stringify(param),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(
                response => {
                    return requestUtil.handleRequest(response);
                },
                error => requestError.errorHandling(error)
            )
            .catch(err => console.error("Caught error: ", err));
    },
    putForUnAuthenticatedWithHeader: (url, param) => {
        return fetch(API_ROOT + url, {
            method: "put",
            body: JSON.stringify(param),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(
                response => {
                    return requestUtil.handleRequest(response);
                },
                error => requestError.errorHandling(error)
            )
            .catch(err => console.error("Caught error: ", err));
    },
    post: (url, param) => {
        return fetch(API_ROOT + url, {
            method: "post",
            body: JSON.stringify(param),
            headers: {
                "Content-Type": "application/json",
                Authorization: Cookies.get("auth-token")
            }
        })
            .then(
                response => {
                    return requestUtil.handleRequest(response);
                },
                error => requestError.errorHandling(error)
            )
            .catch(err => console.error("Caught error: ", err));
    },
    put: (url, param) => {
        return fetch(API_ROOT + url, {
            method: "put",
            body: JSON.stringify(param),
            headers: {
                "Content-Type": "application/json",
                Authorization: Cookies.get("auth-token")
            }
        })
            .then(
                response => {
                    return requestUtil.handleRequest(response);
                },
                error => requestError.errorHandling(error)
            )
            .catch(err => console.error("Caught error: ", err));
    },
    postFile: (url, fileData, data) => {
        let form = new FormData();
        form.append("file", fileData);
        form.append(
            "attachmentManagement",
            new Blob([JSON.stringify(data)], {
                type: "application/json"
            })
        );

        return fetch(API_MAIN_ROOT + url, {
            method: "post",
            body: form,
            headers: {
                Authorization: Cookies.get("auth-token")
            }
        })
            .then(
                response => {
                    return requestUtil.handleRequest(response);
                },
                error => requestError.errorHandling(error)
            )
            .catch(err => console.error("Caught error: ", err));
    },
    get: url => {
        return fetch(API_MAIN_ROOT + url, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
                Authorization: Cookies.get("auth-token")
            }
        })
            .then(
                response => {
                    return requestUtil.handleRequest(response);
                },
                error => requestError.errorHandling(error)
            )
            .catch(err => console.error("Caught error: ", err));
    },
    getForUnAuthenticatedFromMain: url => {
        return fetch(API_MAIN_ROOT + url, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Credentials": true
            }
        })
            .then(
                response => {
                    return requestUtil.handleRequest(response);
                },
                error => requestError.errorHandling(error)
            )
            .catch(err => console.error("Caught error: ", err));
    },
    getForUnAuthenticated: url => {
        return fetch(API_MAIN_ROOT + url, {
            method: "get",
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(
                response => {
                    return requestUtil.handleRequest(response);
                },
                error => requestError.errorHandling(error)
            )
            .catch(err => console.error("Caught error: ", err));
    }
};

const requestError = {
    errorHandling: error => {
        console.log(error);
        return {
            data: {
                type: "serverError",
                payload: {
                    message: "Server Error Happened " + error
                }
            },
            response: undefined
        };
    }
};

const requestUtil = {
    handleRequest: response => {
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.json().then(data => ({
                data: data,
                response: response
            }));
        } else if (contentType && contentType.indexOf("text/plain") !== -1) {
            return response.text().then(data => ({
                data: data,
                response: response
            }));
        } else if (contentType && contentType.indexOf("text/xml") !== -1) {
            return response.blob().then(data => ({
                data: data,
                response: response
            }));
        } else {
            return {
                data: undefined,
                response: response
            };
        }
    }
};

export default {
    Auth,
    Register
};
