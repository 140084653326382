import React, {Fragment, useEffect, useState} from "react";
import {Login, LogOut} from "../header/NavMenuComponents";
import * as Utils from "../../util/Utils";
import * as NotificationsApi from "../../api/NotificationsApi";

import * as LinkFactory from "../../util/LinkFactory";
import {useNavigate} from "react-router-dom";
import {TAG_ASSIGNMENT, TAG_MESSAGE, TAG_POST} from "../../constants/Constants";
import {dateTimeForNotification} from "../../util/DateUtils";
import {connect, useDispatch} from "react-redux";
import {storeSearchQuery} from "../../storeManager/RoomBoardSearch/middleware/RoomBoardSearchMiddleware";

function NoNotification({message}) {
    return (
        <div className="mx-auto my-auto p-5 ">
            <p> {message ? message : "No notification"}</p>
        </div>
    );
}

function TopNavComponent(props) {
    let history = useNavigate();
    const dispatch = useDispatch();

    function navigateTo(pathname) {
        history(pathname);
    }

    const [user, setUser] = useState({});
    const showLogOut = () => !!user;
    const showLogin = () => !user;
    const [hide, setHide] = useState(false);
    const [assignmentCount, setAssignmentCount] = useState(0);
    const [eventNotifications, setEventNotifications] = useState([]);
    const SERVER_NAME = process.env.REACT_APP_FILES_URL;

    const handleHide = () => {
        if (hide === true) {
            props.hide(false);
            setHide(false);
        } else {
            props.hide(true);
            setHide(true);
        }
    };
    const handleMessageRoute = () => {
        clearNotificationCount(TAG_MESSAGE);
        navigateTo(LinkFactory.messageThread);
    };

    const updateNotificationReadStatus = notification => {
        let notificationReadStatusDto = {
            id: notification.id,
            readStatus: true
        };
        NotificationsApi.updateNotificationReadStatus(notificationReadStatusDto).then(res => {
        })
    }

    const markAllAsReadNotificationStatus = (tag) => {
        let notificationReadStatusDto = {
            userId: Utils.getUserFromLocalStorage().id,
            readStatus: false,
            tag: tag
        };
        NotificationsApi.updateAllNotificationReadStatus(notificationReadStatusDto).then(res => {
            console.log(res.data);
        });
    }

    const redirectionController = notification => {
        updateNotificationReadStatus(notification);
        if (notification.tag === "assignment") {
            if (notification.assignmentInd === true) {
                if (notification.assignmentSubmittedInd === true) {
                    navigateTo(LinkFactory.assignmentSubmittedView + "/" + notification.url);
                } else {
                    navigateTo(LinkFactory.assignmentSubmit + "/" + notification.url);
                }
            }
            if (notification.mcqInd === true) {
                if (notification.assignmentSubmittedInd === true) {
                    navigateTo(LinkFactory.mcqSubmittedView + "/" + notification.url);
                } else {
                    navigateTo(LinkFactory.mcqExam + "/" + notification.url);
                }
            }
        } else if (notification.tag === "event") {
            if (notification.eventInd) {
                if (notification.url.includes("/")) {
                    let eventId = notification.url.split("/")[1];
                    navigateTo(LinkFactory.eventsSetupViewForUser + "/" + eventId);
                } else
                    navigateTo(
                        LinkFactory.eventsSetupViewForUser + "/" + notification.url
                    );
            }
        } else if (
            notification.tag === "post" ||
            notification.tag === "post-comment" ||
            notification.tag === "post-like" ||
            notification.postInd === true
        ) {
            if (notification.url.includes("/")) {
                let postId = notification.url.split("/")[1];
                navigateTo(LinkFactory.channelPostView + "/" + postId);
            } else
                navigateTo(LinkFactory.channelPostView + "/" + notification.url);
        }
    };
    const handleAllNotificationRoute = () => {
        navigateTo(LinkFactory.allNotifications);
    }
    useEffect(() => {

    }, [props.notificationPostCounter, props.notificationAssignmentCounter])

    useEffect(() => {
        setUser(Utils.getUserFromLocalStorage());
        // dispatch(fetchNotificationCounter());
        // dispatch(fetchNotificationList());
        // loadAssignmentNotifications();
        // loadPostNotifications();
        console.log('Notification count api calls from here');
    }, []);
    const handleDeleteUserRoute = () => {
        navigateTo(LinkFactory.deleteUser)
    }
    const handleRedirectToHomePage = () => {
        navigateTo(LinkFactory.channelBoard)
    }
    const handleProfileRoute = () => {
        navigateTo(LinkFactory.studentProfile)
    }

    const handleProfileEditRoute = () => {
        navigateTo(LinkFactory.editProfile)
    }

    const changePassword = () => {
        navigateTo(LinkFactory.changePassword);
    }

    function handleAssignmentCountClear() {
        clearNotificationCount(TAG_ASSIGNMENT);
    }

    function handlePostCountClear() {
        clearNotificationCount(TAG_POST);
    }

    function clearNotificationCount(tag) {
        // NotificationsApi.clearNotification(Utils.getUserFromLocalStorage().id, tag).then((res) => {
        //     dispatch(fetchNotificationCounter());
        // })
    }

    function handleRoomRedirect(headerName) {
        if (headerName.length > 0)
            navigateTo(LinkFactory.channelBoard + "/" + Utils.getCurrentChannelId())
    }

    function handleRoomContentSearch(query) {
        if (query.key === 'Enter') {
            dispatch(storeSearchQuery(query.target.value));
        }
    }

    const handleFollowFollowingRoute = () => {
        navigateTo(LinkFactory.followerFollowing)
    }

    const handleLiveMatchesRoute = () => {
        navigateTo(LinkFactory.liveMatches);
    };

    return (
        <div>
            <div className="desktop">
                <div id="topBar">
                    <div className="bar-area">
                        <div className="block-group">
                            <div className="inline-block">
                                <div className="logo-icon">
                                    <a onClick={() => handleRedirectToHomePage()}><img src="img/Iungo Logo.png"/></a>
                                </div>
                            </div>
                            <div className="inline-block">
                                <div className="form-group mb-0 ml-4 has-search">
                                    <select className="form-control">
                                        <option value="" disabled selected>Post Filter</option>
                                        <option value="option1">Option 1</option>
                                        <option value="option2">Option 2</option>
                                    </select>
                                </div>
                            </div>
                            <div className="inline-block">
                                <div className="notifications">
                                    <div className="display-lg sidenav-menu">
                                        <div className="msg">
                                            <div className="dropdown ">
                                                <button
                                                    className="btn-none dropdown-toggle"
                                                    type="button"
                                                    id="dropdownMenuButton"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false">
                                                    <i className="fa fa-bars"/>
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    {/*<div className="assignment">
                                        <div className="dropdown">
                                            <button
                                                onClick={handleLiveMatchesRoute}
                                                className="btn-none dropdown-toggle"
                                                type="button"
                                                id="dropdownMenuButton"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false">
                                                <div className="bell-icon"></div>
                                                <p>Live Matches</p>
                                            </button>
                                        </div>
                                    </div>*/}

                                    <div className="assignment">
                                        <div className="dropdown">
                                            <button
                                                onClick={handleMessageRoute}
                                                className="btn-none dropdown-toggle"
                                                type="button"
                                                id="dropdownMenuButton"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false">
                                                <div className="bell-icon">
                                                    <img src="img/message-icon.png" alt="Chat Icon" className="icon"/>
                                                    {props.notificationChatCounter ? (
                                                        <circle cx="18" cy="6" r="4" fill="#F82B60"/>
                                                    ) : (
                                                        ""
                                                    )}
                                                    {props.notificationChatCounter ?
                                                        <div className="notify-dot">
                                                            <span> {props.notificationChatCounter} </span>
                                                        </div>
                                                        : ''}
                                                </div>
                                                <p>Messages</p>

                                            </button>
                                        </div>
                                    </div>


                                    <div>
                                        <div className="dropdown">
                                            <div className="dropdown-menu "
                                                 aria-labelledby="dropdownMenuButton">
                                                <ul className="scroll-area">
                                                    {props?.notificationList?.assignmentNotifications?.length > 0 ? (
                                                        props?.notificationList?.assignmentNotifications?.map((data, index) => {
                                                            return (
                                                                <a key={index}
                                                                   onClick={() => redirectionController(data)}>
                                                                    {data.readStatus === false ?
                                                                        <div className="notification-block">
                                                                            <div className="notify-content">
                                                                                <div className="circle-icon">
                                                                                    <svg
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        width="16" height="18"
                                                                                        fill="none">
                                                                                        <path fill="#475467"
                                                                                              d="m6.756 13.489 6.266-6.267-1.244-1.244L6.756 11 4.222 8.467 2.978 9.71l3.778 3.778ZM1.778 16.11h12.444V3.667H1.778V16.11ZM0 17.89v-16h5.511c.193-.533.515-.963.967-1.289A2.537 2.537 0 0 1 8 .111c.563 0 1.07.163 1.523.489.451.326.773.756.966 1.289H16v16H0ZM8 3a.648.648 0 0 0 .667-.667.65.65 0 0 0-.189-.478A.65.65 0 0 0 8 1.667a.647.647 0 0 0-.667.666A.645.645 0 0 0 8 3Z"/>
                                                                                        <circle cx="14" cy="16" r="4" fill="red"/>
                                                                                    </svg>

                                                                                </div>
                                                                                <div className="desc">
                                                                                    <h4>{data.body}</h4>
                                                                                    <p><span>{dateTimeForNotification(new Date(data.timestamp).getTime())}</span></p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <div className="read-notification-block">
                                                                            <div className="notify-content">
                                                                                <div className="circle-icon">
                                                                                    <svg
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        width="16" height="18"
                                                                                        fill="none">
                                                                                        <path fill="#475467"
                                                                                              d="m6.756 13.489 6.266-6.267-1.244-1.244L6.756 11 4.222 8.467 2.978 9.71l3.778 3.778ZM1.778 16.11h12.444V3.667H1.778V16.11ZM0 17.89v-16h5.511c.193-.533.515-.963.967-1.289A2.537 2.537 0 0 1 8 .111c.563 0 1.07.163 1.523.489.451.326.773.756.966 1.289H16v16H0ZM8 3a.648.648 0 0 0 .667-.667.65.65 0 0 0-.189-.478A.65.65 0 0 0 8 1.667a.647.647 0 0 0-.667.666A.645.645 0 0 0 8 3Z"/>
                                                                                    </svg>

                                                                                </div>
                                                                                <div className="desc">
                                                                                    {/*<h4>{data.body}</h4>*/}
                                                                                    <h4>{data.body}</h4>
                                                                                    <p>
                                                                                        <span>{dateTimeForNotification(new Date(data.timestamp).getTime())}</span>
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }

                                                                </a>

                                                            );
                                                        })
                                                    ) : (
                                                        <div className="text-center">
                                                            <NoNotification message={"No new assignment"}/>
                                                        </div>
                                                    )}
                                                </ul>

                                                <div className="all-button-area">
                                                    <button className="all-button"
                                                            onClick={handleAllNotificationRoute}> All Notifications
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <div className="dropdown">
                                            <button
                                                onClick={handlePostCountClear}
                                                className="btn-none dropdown-toggle"
                                                type="button"
                                                id="dropdownMenuButton"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false">

                                                <div className="bell-icon">

                                                    <img src="img/notification-icon.png" alt="Notification Icon" className="icon"/>
                                                    {props.notificationPostCounter ?
                                                        <circle cx="18" cy="6" r="4" fill="#F82B60"/> : ""}
                                                    {props.notificationPostCounter ? (
                                                        <div className="notify-dot">
                                                            <span>{props.notificationPostCounter}</span>
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <p>Notification</p>
                                            </button>

                                            <div className="dropdown-menu "
                                                 aria-labelledby="dropdownMenuButton">
                                                <div className="notification-badge">
                                                    <div className="btns">
                                                        <button type="button"
                                                                className="btn btn-primary bg-transparent">
                                                            Post/Event
                                                        </button>
                                                        <div className="unread-messages">
                                                            <p>{props?.notificationPostCounter} unread</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <ul className="scroll-area">
                                                    {props?.notificationList?.postNotifications?.length > 0 ? (
                                                        props?.notificationList?.postNotifications?.map((data, index) => {
                                                            return (
                                                                <a key={index}
                                                                   onClick={() => redirectionController(data)}>
                                                                    {data.readStatus === false ?
                                                                        <div className="notification-block">
                                                                            <div className="notify-content">
                                                                                <div className="circle-icon">
                                                                                    <img
                                                                                        src="img/notification-unread-icon.png"
                                                                                        alt={"Notification icon"}/>

                                                                                </div>
                                                                                <div className="desc">
                                                                                    <h4>{data.body} </h4>
                                                                                    <p>
                                                                                        <span>{dateTimeForNotification(new Date(data.timestamp).getMilliseconds())}</span>
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <div className="read-notification-block">
                                                                            <div className="notify-content">
                                                                                <div className="circle-icon mr-1">
                                                                                    <img
                                                                                        src="img/notification-read-icon.png"
                                                                                        alt={"Notification icon"}/>
                                                                                </div>
                                                                                <div className="desc">
                                                                                    <h4>{data.body}</h4>
                                                                                    <p>
                                                                                        <span>{dateTimeForNotification(new Date(data.timestamp).getTime())}</span>
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </a>
                                                            );
                                                        })
                                                    ) : (
                                                        <NoNotification message={"No new post"}/>
                                                    )}
                                                </ul>
                                                <div className="all-button-area">
                                                    <button className="all-button"
                                                            onClick={handleAllNotificationRoute}> All Notifications
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="profile-dropdown">
                                    <div className="avatar">
                                        {user?.imageUrl ? (
                                            <img
                                                onClick={handleProfileRoute}
                                                src={SERVER_NAME + user.imageUrl}
                                                alt="new"
                                            />
                                        ) : (
                                            <img
                                                src={"img/Avatar.png"}
                                                alt="Default Avatar"
                                            />
                                        )}
                                    </div>
                                    <div className="dropdown show">
                                        <a
                                            className="dropdown-toggle"
                                            href="#"
                                            role="button"
                                            id="dropdownMenuLink"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            {user?.firstName} {user?.lastName}
                                        </a>

                                        <div className="dropdown-menu"
                                             aria-labelledby="dropdownMenuLink">
                                            <a className="dropdown-item" onClick={handleFollowFollowingRoute}>
                                                <img src="img/followers-following.png" alt="Followers Following Icon" className="icon"/>
                                                Follower/Following
                                            </a>
                                            <a className="dropdown-item" onClick={handleProfileRoute}>
                                                <img src="img/user.png" alt="User Icon" className="icon"/>
                                                My Profile
                                            </a>
                                            <a className="dropdown-item" onClick={handleProfileEditRoute}>Edit Profile</a>
                                            {showLogin() ? <Login/> : <Fragment/>}
                                            {showLogOut() ? <LogOut/> : <Fragment/>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

// export default TopNavComponent;
const mapStateToProps = state => ({
    channels: state.channels.channels,
    notificationPostCounter: state.notificationCounter.notificationPostCounter,
    notificationAssignmentCounter: state.notificationCounter.notificationAssignmentCounter,
    notificationChatCounter: state.notificationCounter.notificationChatCounter,
    notificationList: state.notificationList,
    loading: state.channels.loading,
    error: state.channels.error
});

export default connect(mapStateToProps)(TopNavComponent);
