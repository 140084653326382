import React, {useEffect, useState} from "react";
import CommonLayout from "../layout/commonLayout";
import {Field, FieldArray, Form, Formik} from "formik";
import TextFieldComponent from "../common/TextFieldComponent";
import FormTitle from "../Typhography/formTitle";
import FileUploader from "../Uploader/fileUploader";
import DatePicker from "react-datepicker";
import {Typeahead} from 'react-bootstrap-typeahead';
import * as CommonApi from "../../api/CommonApi";
import * as LiveMatchApi from "../../api/LiveMatchApi";
import {useNavigate} from "react-router-dom";
import * as LinkFactory from "../../util/LinkFactory";

export default function AddNewLiveMatch(props) {

    const navigate = useNavigate();

    const initState = {
        title: "",
        location: "",
        thumbnailBase64: "",
        thumbnailFileName: "",
        startTime: new Date(),
        streamerInfoList: [],
    }

    const [liveMatchData, setLiveMatchData] = useState(initState);
    const [userList, setUserList] = useState([]);


    useEffect(() => {
        setLiveMatchData({...liveMatchData, streamerInfoList: [{title: "", streamerUserId: ""}]})
    }, []);

    const handleSubmit = values => {
        LiveMatchApi.saveLiveMatchInfo(values)
            .then(resp => {
                if (resp.data?.status === 200){
                    navigate(LinkFactory.liveMatches)
                }
            })
    }

    const fileToDataUri = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = event => {
            resolve(event.target.result);
        };
        reader.readAsDataURL(file);
    });

    function fetchUser(q) {
        CommonApi.userTypeHeadSearch({q})
            .then(resp => {
                setUserList(resp.data ? resp.data : [])
            })
    }

    const DatePickerComponent = ({field, form: {touched, errors, setFieldValue}}) => {
        return (
            <div className="mr-2">
                <DatePicker
                    showTimeInput2
                    selected={Date.parse(field.value)}
                    placeholderText="Pick date and time"
                    onChange={value => setFieldValue(field.name, value)}
                    className="form-control btn-block w-100 overflow-auto "
                    timeInputLabel="Time:"
                    dateFormat="MM/dd/yyyy HH:mm"
                    showTimeInput
                />
            </div>
        );
    };

    return (<>
        <div className="homepage">
            <CommonLayout headerName={""}>
                <main>
                    <div className="d-flex justify-content-end">
                        <div className="title ">
                        </div>
                        <div className="room-body card-default content-section container-responsive create-live-matches">
                            <div className="create-match-header">
                                <p className="mt-1"> Create Matches</p>
                            </div>
                            <div>
                                <Formik
                                    initialValues={liveMatchData}
                                    enableReinitialize={true}
                                    onSubmit={handleSubmit}
                                >
                                    {({ values, setFieldValue }) => (
                                        <Form className="create-match-form w-70">
                                            <div className="form-group">
                                                <label className="font-weight-medium text-gray-25 mb-2">Title</label>
                                                <Field
                                                    type="text"
                                                    name="title"
                                                    placeholder="Enter match title"
                                                    component={TextFieldComponent}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label className="font-weight-medium text-gray-25 mb-2">
                                                    Description
                                                </label>
                                                <Field
                                                    type="text"
                                                    name="description"
                                                    placeholder="Enter match description"
                                                    component={TextFieldComponent}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label className="font-weight-medium text-gray-25 mb-2">
                                                    Location
                                                </label>
                                                <Field
                                                    type="text"
                                                    name="location"
                                                    placeholder="Enter location"
                                                    component={TextFieldComponent}
                                                />
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group">
                                                    <label className="font-weight-medium text-gray-25 mb-2">Streaming Date & Time</label>
                                                    <Field
                                                        name="date"
                                                        placeholder="Select date"
                                                        component={DatePickerComponent}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="font-weight-medium text-gray-25 mb-2">
                                                    Thumbnail
                                                </label>
                                                <FileUploader
                                                    onChange={(files) => {
                                                        if (files[0]) {
                                                            values.thumbnailFileName = files[0].name;
                                                            fileToDataUri(files[0]).then((data) => {
                                                                values.thumbnailBase64 = data.split(",")[1];
                                                                setFieldValue("thumbnail", values.thumbnailBase64);
                                                            });
                                                        }
                                                    }}
                                                />
                                            </div>
                                            {/*<div className="form-group">*/}
                                            {/*    <label className="font-weight-medium text-gray-25 mb-2">*/}
                                            {/*        Streaming Type*/}
                                            {/*    </label>*/}
                                            {/*    <Field*/}
                                            {/*        as="select"*/}
                                            {/*        name="streamingType"*/}
                                            {/*        className="form-control"*/}
                                            {/*    >*/}
                                            {/*        <option value="cameraFeed">Camera Feed</option>*/}
                                            {/*        <option value="liveStream">Live Stream</option>*/}
                                            {/*    </Field>*/}
                                            {/*</div>*/}
                                            <FieldArray
                                                name="streamerInfoList"
                                                render={(arrayHelpers) => (
                                                    <div className="form-group">
                                                        {values.streamerInfoList &&
                                                            values.streamerInfoList.map((item, index) => (
                                                                <div className="streamer-item" key={index}>
                                                                    <div className="form-row">
                                                                        <div className="form-group">
                                                                            <label>Streamer Title</label>
                                                                            <Field
                                                                                type="text"
                                                                                name={`streamerInfoList[${index}].title`}
                                                                                component={TextFieldComponent}
                                                                            />
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label>Search Streamer</label>
                                                                            <Typeahead
                                                                                onChange={(selected) => {
                                                                                    if (selected.length > 0) {
                                                                                        setFieldValue(
                                                                                            `streamerInfoList[${index}].streamerUserId`,
                                                                                            selected[0].id
                                                                                        );
                                                                                    }
                                                                                }}
                                                                                onInputChange={(query) => {
                                                                                    if (query && query.trim().length > 3)
                                                                                        fetchUser(query);
                                                                                }}
                                                                                labelKey="fullName"
                                                                                options={userList}
                                                                                placeholder="Search for a user..."
                                                                            />
                                                                        </div>
                                                                        <div className="actions">
                                                                            <button
                                                                                type="button"
                                                                                onClick={() => arrayHelpers.remove(index)}
                                                                                className="btn-red-lg radius-25"
                                                                            >
                                                                                Remove
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        <button
                                                            type="button"
                                                            onClick={() =>
                                                                arrayHelpers.push({ title: "", streamerUserId: "" })
                                                            }
                                                            className="btn btn-primary mt-2 w-20"
                                                        >
                                                            Add Streamer
                                                        </button>
                                                    </div>
                                                )}
                                            />

                                            <div className="form-group">
                                                <label className="font-weight-medium text-gray-25 mb-2">
                                                    Streaming Rules (Optional)
                                                </label>
                                                <Field
                                                    type="text"
                                                    name="streamingRules"
                                                    placeholder="Enter streaming rules"
                                                    component={TextFieldComponent}
                                                />
                                            </div>

                                            <div className="form-group text-center">
                                                <button type="submit" className="btn-red-lg radius-25 w-20">
                                                    Create Live
                                                </button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </main>
            </CommonLayout>
        </div>
    </>);
}

